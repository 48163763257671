import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styles from './CrystalCard.module.scss';
import useToggle from '@hooks/useToggle';
import LinkButton from '@components/atoms/Buttons/LinkButton';
import CardSide from './CardSide';
import RubyImage from '@images/ruby.webp';
import SaphireImage from '@images/saphire.webp';
import BlueImage from '@images/blue.webp';
import AquamarineImage from '@images/aquamarine.webp';
import GreenImage from '@images/green.webp';
import TypeThreeImage from '@images/crystal_7.webp';
import TypeTwoImage from '@images/crystal_6.webp';
import TypeOneImage from '@images/crystal_5.webp';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const CardWrapper = ({
  children,
  campaignLink,
  cardClickHandler,
  stylesCustom,
  id,
  isSwiper,
  index,
}) => {
  return campaignLink ? (
    campaignLink !== 'expired' ? (
      <LinkButton href={campaignLink} stylesCustom={stylesCustom}>
        {children}
      </LinkButton>
    ) : (
      <div className={stylesCustom}>{children}</div>
    )
  ) : isSwiper ? (
    <div
      role="presentation"
      id={id}
      onKeyUp={cardClickHandler}
      onClick={cardClickHandler}
      className={stylesCustom}
    >
      {children}
    </div>
  ) : (
    <button id={id} onClick={cardClickHandler} className={stylesCustom}>
      {children}
    </button>
  );
};

const CrystalCard = ({
  image,
  startDate,
  endDate,
  title,
  location,
  description,
  campaignLink,
  customStyles,
  onClick,
  id,
  type,
  category,
  index,
  isInView,
  isSwiper,
}) => {
  const imageTypeMap = {
    'Type 1': TypeOneImage,
    'Type 2': TypeTwoImage,
    'Type 3': TypeThreeImage,
    ruby: RubyImage,
    saphire: SaphireImage,
    blue: BlueImage,
    aquamarine: AquamarineImage,
    green: GreenImage,
  };

  const { toggled, handleToggleClick } = useToggle(false);
  const anchorClickHandler = (e) => e.stopPropagation();
  const cardClickHandler = (e) => {
    (isSwiper || index === 0) && handleToggleClick();
    onClick && onClick(e);
  };

  const cardClickEffect = () => {
    if (isSwiper) return;
    if (!isInView && toggled) {
      handleToggleClick();
      onClick && onClick();
    }
  };
  useEffect(cardClickEffect, [
    handleToggleClick,
    isInView,
    isSwiper,
    onClick,
    toggled,
  ]);

  return (
    <CardWrapper
      cardClickHandler={cardClickHandler}
      stylesCustom={`${customStyles ?? ''} ${styles.wrapper}`}
      campaignLink={campaignLink}
      onClick={onClick}
      id={id}
      isSwiper={isSwiper}
      index={index}
    >
      <div
        className={`${
          styles[image?.type || type.toLowerCase().replace(' ', '')]
        } ${styles.flipContent} ${
          toggled ? styles.clicked : styles.darkShadow
        } ${campaignLink ? '' : 'z-[-1]'}`}
      >
        <LazyLoadImage
          src={imageTypeMap[image?.type || type]}
          className="rounded-20 w-full h-full"
          wrapperClassName={`transition-all duration-700 absolute h-full w-full rounded-20 ${
            !toggled ? 'opacity-100' : 'opacity-0'
          }`}
        />
        <CardSide
          category={category}
          anchorClickHandler={anchorClickHandler}
          isFrontSide
          data={{ startDate, endDate, title, location }}
          styleModule={{
            flip: `${styles.flipFront} ${!toggled ? 'z-10' : 'z-[-1]'}`,
            dateWrapper: styles.frontDateWrapper,
            frontLocationWrapper: styles.frontLocationWrapper,
            cardTitle: styles.frontTitle,
            locationTitle: styles.frontLocationTitle,
          }}
        />
        {!campaignLink && (
          <CardSide
            anchorClickHandler={anchorClickHandler}
            data={{ startDate, endDate, title, location, description }}
            styleModule={{
              flip: `${styles.flipBack} ${toggled ? 'z-10' : 'z-[-1]'}`,
              dateWrapper: styles.backDateWrapper,
              cardTitle: styles.backTitle,
              locationTitle: styles.backLocationTitle,
              backDescription: styles.backDescription,
              backLocationWrapper: styles.backLocationWrapper,
            }}
          />
        )}
      </div>
    </CardWrapper>
  );
};

CrystalCard.propTypes = {
  campaignLink: PropTypes.string,
  category: PropTypes.any,
  customStyles: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  description: PropTypes.string,
  endDate: PropTypes.string,
  id: PropTypes.number,
  image: PropTypes.shape({
    type: PropTypes.string,
  }),
  index: PropTypes.number,
  isInView: PropTypes.bool,
  isSwiper: PropTypes.bool,
  location: PropTypes.shape({
    uri: PropTypes.string,
    title: PropTypes.string,
  }),
  onClick: PropTypes.func,
  startDate: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default CrystalCard;
