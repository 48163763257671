import React, { useEffect, useRef, useState } from 'react';
import Image from '@components/molecules/ImageGatsby';
import { breakpoints } from '@helpers/constants';
import useBreakpoint from '@hooks/useBreakpoint';
import { useInView } from 'framer-motion';
import PropTypes from 'prop-types';
import ShareButton from '@components/molecules/ShareButton/ShareButton';
import { Interweave } from 'interweave';
import styles from './HeroSection.module.scss';
import FixedItem from '@components/molecules/FixedItem';
import ScrollIcon from '@images/scroll_icon.svg';
import { useIsTop } from '@hooks/useIsTop';
import { scrollToElement } from '@helpers/scrollToSection';

const HeroSection = ({
  content,
  heroImageMobile,
  heroImage,
  scrollTo,
  hideContent = false,
  leftContent,
  rightContent,
  toggled,
  withShareButton,
  handleToggleClick,
  dataId,
  animateHero,
  icon,
  postScroll,
  notSection,
  Line,
  withImageOverlay,
}) => {
  const isMobile = useBreakpoint(breakpoints.sm);
  const isMiddle = useBreakpoint(breakpoints.md);
  const [renderImage, setRenderImage] = useState(false);
  const ref = useRef(null);
  const isTop = useIsTop();

  const isInView = useInView(ref, { once: true });

  const delayImageRender = () => {
    setTimeout(() => setRenderImage(true), animateHero ? 0 : 500);
  };
  useEffect(delayImageRender, [animateHero]);

  const scrollToHandler = () => {
    postScroll
      ? window.scrollTo({
          top: ref.current?.getBoundingClientRect().height,
          behavior: 'smooth',
        })
      : scrollToElement(scrollTo);
  };

  return (
    <>
      {icon ? (
        <div className="absolute top-0 left-0 right-0 bottom-0 gradient-blog-card flex justify-center items-center">
          {icon && (
            <i
              className={`text-aquamarine opacity-20 ${icon} text-[200px] md:text-[500px]`}
            />
          )}
        </div>
      ) : (
        <>
          {withImageOverlay && (
            <div className="gradient-blog-card absolute top-0 left-0 right-0 bottom-0 z-[1] opacity-60"></div>
          )}
          <Image
            image={isMobile ? heroImageMobile : heroImage}
            fill
            className={`transition-all duration-700 pointer-events-none ${
              animateHero
                ? renderImage
                  ? 'scale-[100%] translate-x-0 translate-y-0'
                  : 'scale-[110%] sm:scale-[220%] sm:translate-x-[-50%]'
                : renderImage
                ? 'opacity-100'
                : 'opacity-0'
            } `}
          />
        </>
      )}
      <div
        id="hero-section"
        className={`${styles.wrapper} ${
          notSection && isMobile ? '' : 'section'
        }`}
        data-id={dataId}
        ref={ref}
      >
        {!hideContent && (
          <div
            className={`${styles.content} ${
              isInView ? 'in-view' : 'out-of-view'
            } transition-1000`}
          >
            <div className={styles.tagWrapper}>
              {!withImageOverlay && !isMobile && <div className="tag-line" />}
              <p
                className={`tag ${
                  withImageOverlay ? '!text-white opacity-50 !ml-0' : ''
                }`}
              >
                {content.name}
              </p>
              {!withImageOverlay && isMobile && <div className="tag-line" />}
            </div>
            <h1 className={styles.description}>
              <Interweave
                content={content.description || content.title}
                allowAttributes
                allowElements
              />
            </h1>
            {content.excerpt && (
              <div className={styles.excerpt}>
                <Interweave
                  content={content.excerpt}
                  allowAttributes
                  allowElements
                />
              </div>
            )}
            <button onClick={scrollToHandler}>
              <img
                src={ScrollIcon}
                alt="scroll-icon"
                className="inline-block"
              />
            </button>
            {!isMiddle && Line && !toggled && (
              <div className={`absolute left-[3.5%] right-0`}>{Line}</div>
            )}
          </div>
        )}
        <div className={styles.iconsWrapper}>
          <FixedItem
            toggled={toggled}
            text={leftContent?.text}
            url={leftContent?.readMoreLink?.url}
            title={leftContent?.readMoreLink?.title}
            icon={leftContent?.icon}
            leftAligned
            fadeOnScroll
            isTop={isTop}
          >
            {withShareButton && <ShareButton />}
          </FixedItem>

          <FixedItem
            toggled={toggled}
            handleToggleClick={handleToggleClick}
            text={rightContent?.text}
            url={rightContent?.readMoreLink?.url}
            title={rightContent?.readMoreLink?.title}
            icon={rightContent?.icon}
            scrollTo={rightContent?.scrollTo}
            fadeOnScroll
            isTop={isTop}
          />
        </div>
      </div>
    </>
  );
};

HeroSection.propTypes = {
  animateHero: PropTypes.bool,
  content: PropTypes.shape({
    description: PropTypes.string,
    excerpt: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
  }),
  dataId: PropTypes.number,
  handleToggleClick: PropTypes.func,
  heroImage: PropTypes.string,
  heroImageMobile: PropTypes.string,
  hideContent: PropTypes.bool,
  icon: PropTypes.string,
  leftContent: PropTypes.shape({
    icon: PropTypes.string,
    readMoreLink: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    text: PropTypes.string,
  }),
  notSection: PropTypes.bool,
  postScroll: PropTypes.bool,
  rightContent: PropTypes.shape({
    icon: PropTypes.string,
    readMoreLink: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    scrollTo: PropTypes.string,
    text: PropTypes.string,
  }),
  scrollTo: PropTypes.string,
  toggled: PropTypes.bool,
  withShareButton: PropTypes.bool,
};

export default HeroSection;
