import { graphql } from 'gatsby';
import React, { useMemo } from 'react';
import SEO from '@components/molecules/SEO';
import Blocks from '@components/blocks/Blocks';
import CalendarSection from '@components/organisms/CalendarSection';
import { breakpoints } from '@helpers/constants';
import useBreakpoint from '@hooks/useBreakpoint';
import Layout from '@layouts/Layout';
import TopFixedSection from '@components/organisms/TopFixedSection';
import HeroSection from '@components/organisms/HeroSection';
import useToggle from '@hooks/useToggle';
import { removeEmptyEntries } from '@helpers/removeEmptyEntries';
import ShareSection from '@components/molecules/ShareSection';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import AuthForm from '@components/organisms/AuthForm';
import { isPostLocked } from '@helpers/isPostLocked';
import { useUserAuth } from '@hooks/useUserAuth';
import CommentSection from '@components/molecules/CommentSection';
import { iconMap } from '@helpers/constants';
import { useScrollToTop } from '@hooks/useScrollToTop';

const Page = ({
  data: {
    allWp: {
      nodes
    },
    wpPost,
    wp: {
      siteSettings: {
        siteSettings: {
          commentsHeading,
          commentsSectionName,
          blogPostsActionButton,
          loginFormTitle,
          userLoginForgotPasswordLabel,
          userLoginForm,
          userLoginFormErrorMessages,
          userLoginRegisterButton,
          userLoginInformation,
          relatedPostsHeading,
          relatedPostsSectionName,
          eventsSettingsAccordionTitle,
          menuText,
          menuLogo,
        },
      },
    },
  },
}) => {
  const {
    dispatch,
    loading,
    errorMessage,
    apolloError,
    isLoggedIn,
    submitFormHandler,
    state,
  } = useUserAuth(userLoginFormErrorMessages, true);

  const language = 'bg';
  const currentLanguage = language ? language.slug : 'en';
  const { toggled, handleToggleClick } = useToggle(false);
  const isLarge = useBreakpoint(breakpoints.lg);
  const filteredBlocks = removeEmptyEntries(wpPost.blocks);
  const isLocked = useMemo(
    () => isPostLocked(isLoggedIn, wpPost.postMeta.blogPostStatus),
    [isLoggedIn, wpPost.postMeta.blogPostStatus],
  );

  const {
    toggled: isForgottenPassword,
    handleToggleClick: toggleForgottenPassword,
  } = useToggle(false);

  const inputChangeHandler = ({ target: { name, value } }) => {
    dispatch({
      type: name,
      payload: value,
    });
  };

  useScrollToTop();

  return (
    <Layout
      translations={[]}
      currentLanguage={currentLanguage}
      lang={language.locale}
      isLoggedIn={isLoggedIn}
      hideParallax={isLocked || !filteredBlocks || filteredBlocks?.length === 0}
      showFooter={!isLocked}
    >
      <SEO
        title={wpPost.seo?.title}
        uri={wpPost.uri}
        lang={language.locale}
        headJson={wpPost.seo?.headJSON}
        translations={[]}
      />
      <TopFixedSection
        menuText={menuText}
        menuLogo={menuLogo}
        handleToggleClick={handleToggleClick}
        toggled={toggled}
        generalSettingsUrl={nodes[0].allSettings.generalSettingsUrl}
        
      />
      <HeroSection
        {...(wpPost.postMeta.blogPostType !== 'default' && {
          icon: iconMap[wpPost.postMeta.blogPostType],
        })}
        content={{
          name: `${wpPost.categories.nodes[0].name}, ${new Date(
            wpPost.date,
          ).toDateString()}`,
          excerpt: wpPost.excerpt,
          title: wpPost.title,
        }}
        heroImage={wpPost.featuredImage?.node.publicUrl}
        heroImageMobile={wpPost.featuredImage?.node.publicUrl}
        hideContent={isLocked && isLarge}
        postScroll={!isLocked}
        toggled={toggled}
        notSection={isLocked && !isLoggedIn}
        withShareButton
        withImageOverlay
      />
      {isLocked ? (
        <div className="absolute z-10 top-[50%] translate-y-[-50%] translate-x-[50%] md:translate-x-0 right-[50%] md:right-[10%]">
          <AuthForm
            toggleForgottenPassword={toggleForgottenPassword}
            isForgottenPassword={isForgottenPassword}
            withoutPaddingTop
            submitFormHandler={submitFormHandler}
            changeHandler={inputChangeHandler}
            formTitle={loginFormTitle}
            formContent={userLoginForm}
            errorMessage={errorMessage}
            apolloError={apolloError}
            loading={loading}
            sideLabel={userLoginInformation}
            sideButton={userLoginRegisterButton}
            state={state}
            forgottenPasswordLabel={userLoginForgotPasswordLabel}
          />
        </div>
      ) : (
        <>
          <Blocks
            blocks={filteredBlocks}
            eventsSettingsAccordionTitle={eventsSettingsAccordionTitle}
          />
          <ShareSection spacing="mx-10 md:mx-[10%] lg:mx-[15%] my-20 md:my-36" />
          {wpPost.commentStatus === 'open' && (
            <LazyLoadComponent>
              <CommentSection
                userLoginInformation={userLoginInformation}
                userLoginRegisterButton={userLoginRegisterButton}
                postId={wpPost.databaseId}
                comments={wpPost.comments.nodes.filter(
                  (comment) => !comment.parentId,
                )}
                commentsHeading={commentsHeading}
                commentsSectionName={commentsSectionName}
                length={wpPost.commentCount}
                isLoggedIn={isLoggedIn}
              />
            </LazyLoadComponent>
          )}
          {wpPost.postMeta.relatedPosts && (
            <CalendarSection
              blogPostsActionButton={blogPostsActionButton}
              cards={wpPost.postMeta.relatedPosts.map((post) => post.post)}
              content={{
                name: relatedPostsSectionName,
                title: relatedPostsHeading,
              }}
              initialSlide={0}
              id="calendar-section"
              selectCardStyles="regularCardStyles"
            />
          )}
        </>
      )}
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query POST_PAGE($id: String!) {
    allWp {
      nodes {
        allSettings {
          generalSettingsUrl
        }
      }
    }
    wpPost(id: { eq: $id }) {
      databaseId
      title
      uri
      seo {
        headJSON
        title
      }
      excerpt
      date
      featuredImage {
        node {
          publicUrl
        }
      }
      title
      categories {
        nodes {
          name
        }
      }
      commentStatus
      commentCount
      comments {
        nodes {
          databaseId
          date
          content
          id
          replies {
            nodes {
              date
              content
              id
              author {
                node {
                  avatar {
                    url
                  }
                  name
                }
              }
            }
          }
          parentId
          author {
            node {
              avatar {
                url
              }
              name
            }
          }
        }
      }
      postMeta {
        blogPostStatus
        blogPostType
        relatedPosts {
          post {
            ... on WpPost {
              postMeta {
                blogPostStatus
                blogCardSize
              }
              id
              uri
              categories {
                nodes {
                  slug
                  name
                }
              }
              date(formatString: "D/M/yyyy")
              excerpt
              title
              featuredImage {
                node {
                  publicUrl
                }
              }
            }
          }
        }
      }
      ...BlocksContentPost
    }
    wp {
      siteSettings {
        siteSettings {
          commentsHeading
          commentsSectionName
          blogPostsActionButton
          userLoginForgotPasswordLabel
          userLoginForm {
            button
            email
            password
          }
          userLoginFormErrorMessages {
            emptyField
            invalidField
            requiredField
          }
          userLoginInformation
          userLoginRegisterButton {
            title
            url
          }
          loginFormTitle
          relatedPostsHeading
          relatedPostsSectionName
          eventsSettingsAccordionTitle
          menuText
          menuLogo {
            altText
            mediaItemUrl
          }
          campaignsForm {
            firstName
            lastName
            email
            button
            campaignType
          }
          campaignsFormErrorMessages {
            emptyField
            invalidField
            requiredField
          }
          campaignsFormTitle
          campaignsFormPrivacyPolicyText
          campaignsFormPrivacyPolicyLink {
            target
            title
            url
          }
        }
      }
    }
  }
`;
