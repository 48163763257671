import { useContext, useReducer, useState } from 'react';
import { apolloErrors } from '@helpers/constants';
import { getValidationSchema } from '@helpers/getValidationSchema';
import { SiteContext } from '@store/Provider';
import { useMutation } from '@apollo/client';
import { authReducer } from '@helpers/authReducer';
import { loginInitialState } from '@helpers/constants';
import { FORGOTTEN_PASSWORD, LOGIN } from '@apolloClient/mutations';
import { useGetUserWithId } from './useGetUserWithId';

export const useUserAuth = (userLoginFormErrorMessages, reValidateUser) => {
  useGetUserWithId(reValidateUser);

  const [isForgottenPassword, setIsForgottenPassword] = useState(false);
  const toggleForgottenPassword = () =>
    setIsForgottenPassword((prevState) => !prevState);

  const [state, dispatch] = useReducer(authReducer, loginInitialState);
  const { isLoggedIn, setIsLoggedIn, setUser } = useContext(SiteContext);
  const [login, { loading }] = useMutation(LOGIN);

  const [
    sendPasswordResetEmail,
    { loading: forgottenPasswordLoading },
  ] = useMutation(FORGOTTEN_PASSWORD);

  const [errorMessage, setErrorMessage] = useState([]);
  const [apolloError, setApolloError] = useState('');

  const validationSchema = getValidationSchema(
    isForgottenPassword ? ['email'] : ['password', 'email'],
    userLoginFormErrorMessages,
  );

  const submitFormHandler = async (submitEvent) => {
    submitEvent.preventDefault();
    setErrorMessage([]);
    setApolloError('');
    try {
      await validationSchema.validate(state, { abortEarly: false });

      if (isForgottenPassword) {
        const response = await sendPasswordResetEmail({
          variables: {
            username: state.email,
          },
        });

        if (response.data?.sendPasswordResetEmail?.success) {
          toggleForgottenPassword();
        }

        return;
      }

      const response = await login({
        variables: {
          username: state.email,
          password: state.password,
        },
      });
      localStorage.setItem('token', response.data.login.authToken);
      localStorage.setItem('refreshToken', response.data.login.refreshToken);
      localStorage.setItem('userId', response.data.login.user.id);

      setUser({
        username: response.data.login.user.username,
        email: response.data.login.user.email,
      });

      setIsLoggedIn(true);
    } catch (error) {
      if (error.name === 'ApolloError') {
        setApolloError(apolloErrors[error.message]);
      }

      error.inner &&
        setErrorMessage(
          error.inner.map((innerError) => ({
            [innerError.path]: innerError.message,
          })),
        );
    }
  };

  return {
    dispatch,
    loading,
    errorMessage,
    apolloError,
    isLoggedIn,
    submitFormHandler,
    state,
    forgottenPasswordLoading,
    toggleForgottenPassword,
    isForgottenPassword,
  };
};
