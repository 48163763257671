import PropTypes from 'prop-types';
import React from 'react';
import AnchorButton from '@components/atoms/Buttons/AnchorButton';
import styles from './CrystalCard.module.scss';

const LocationWrapper = ({ locationLink, anchorClickHandler, children }) => {
  return locationLink ? (
    <AnchorButton
      notStylized
      target="__blank"
      href={locationLink}
      onClick={anchorClickHandler}
    >
      {children}
    </AnchorButton>
  ) : (
    <div>{children}</div>
  );
};

const CardSide = ({
  data: {
    startDate,
    endDate,
    title,
    location,
    anchorClickHandler,
    description,
  },
  styleModule: {
    flip,
    dateWrapper,
    frontLocationWrapper,
    cardTitle,
    locationTitle,
    backDescription,
    backLocationWrapper,
  },
  isFrontSide,
  category,
}) => {
  const categoryIconMap = {
    'category-1': 'icon-interface-favorite-give-heart',
    'category-2': 'icon-interface-edit-pen-2',
    'category-3': 'icon-interface-edit-paint',
  };
  return (
    <div className={flip}>
      <div className={dateWrapper}>
        <p className={`${isFrontSide ? '' : 'text-graphite'}`}>
          <i className="icon-interface-calendar-check" />
          <span>{startDate}</span>
          {' - '}
          <span>{endDate}</span>
        </p>
      </div>
      <div
        className={`${
          isFrontSide ? frontLocationWrapper : backLocationWrapper
        }`}
      >
        <h2 className={cardTitle}>{title}</h2>
        {!isFrontSide && <p className={backDescription}>{description}</p>}
        {location ? (
          <div className={styles.locationInnerWrapper}>
            <LocationWrapper
              locationLink={location.uri}
              anchorClickHandler={anchorClickHandler}
            >
              <p className={locationTitle}>
                <span>{location.title}</span>
                <i className="icon-travel-map-location-pin" />
              </p>
            </LocationWrapper>
          </div>
        ) : (
          <div className={styles.locationInnerWrapper}>
            <p className="flex justify-end items-center gap-10">
              <span>{category?.name}</span>
              <i
                className={`text-[24px] border rounded-full p-4 ${
                  categoryIconMap[category?.slug]
                }`}
              />
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

CardSide.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  isFrontSide: PropTypes.bool,
  data: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.shape({
      uri: PropTypes.string,
      title: PropTypes.string,
    }),
    anchorClickHandler: PropTypes.func,
    description: PropTypes.string,
  }),
};
export default CardSide;
